import * as React from 'react';

import { HeroNav, Layout, SEO } from '../components';

const TermsAndConditions = () => (
  <Layout
    formName="covid-page-bottom-form"
    formTitle="Buying or hiring? Contact us today"
    hideForm
  >
    <SEO
      title="Terms and conditions - Your Health Comes First | Frontline Removals"
      description="Your trusted Removalists in NSW. Frontline Removals is located in Port Macquarie on the mid-North Coast. The perfect base for Brisbane and Sydney removals.
      "
    />
    <div className="px-6 py-4">
      <HeroNav />
    </div>
    <article className="max-w-6xl px-6 py-4 mx-auto mt-20 ">
      <div className="rich-text">
        <p>
          Frontline Removals is proud to be an Australian owned business that
          employs locally and supports local businesses. We’re the removal
          professionals for all your local, country and interstate relocations.
          Our team provide a caring and reliable service with personalised
          attention to suit your individual needs.
        </p>
        <p>
          <strong>
            {' '}
            All work is undertaken subject to the attached Terms &amp;
            Conditions of Removals and Storage.
          </strong>
          The total of this quotation does not include any credit card
          transaction fee.{' '}
          <strong>
            Please note that this quotation is based the information you
            supplied prior to acceptance. Any changes to this list and failure
            to supply information about anything other than reasonable access
            may incur additional charges.
          </strong>
        </p>
        <h3>For your protection</h3>
        <p>
          Read the Conditions of Removal and Storage carefully. If you have any
          queries, please do not hesitate to contact us.{' '}
          <strong>
            All clients must provide a list of items to be moved prior to
            booking and is considered to be all we are taking, any additional
            items required to be transported at the point of uplift will be
            charged at a pro rata rate.
          </strong>{' '}
          Ensure that you or your agent is at uplift and delivery. Check your
          consignment promptly and list any discrepancies on the delivery note.
        </p>
        <h3>We highly recommend that you</h3>
        <p>
          <strong>Engage us to pack and unpack</strong> - this minimizes the
          risk of damage and helps avoid disputes.{' '}
          <strong>
            All Pre-fabricated furniture (i.e IKEA/Bunnings furniture etc.) that
            is transported assembled, disassembled &amp; reassembled by our
            removal team is done so at owner’s risk. All Televisions are
            transported at owners risk, we don’t test them on uplift or
            delivery, non-working TV’s with no physical damage are not covered
            under any insurance, ours or yours.
          </strong>
        </p>
        <p>
          <strong>
            We recommend that you contact the manufacture of your goods for
            correct procedures for transport, including but not limited to, all
            white goods, Mattresses &amp; electrical appliances.
          </strong>
        </p>

        <h3>Deposit</h3>
        <p>
          A Deposit of $200 or 10% (whichever is greater) of which is required
          for payment within 24 hours of making a booking or accepting a
          quotation. Any deposit paid is strictly non-refundable (subject to our
          discretion or specific circumstances).
        </p>
        <h3>Payment</h3>
        <p>
          Balance payments on intra and interstate relocations are required to
          be settled a minimum of 3 working days prior to the uplift of goods or
          delivery ex store by either cash or credit card 1.5% surcharge applies
          for all Credit cards or direct bank deposit.
        </p>

        {/* <p>
          <strong>Yours Sincerely,</strong>
          <br />
          <strong>Jamie Carter</strong>
          <br />
          <strong>General Manage, Frontline Removals</strong>
        </p> */}
        {/* <p className="italic">
          If you have any further questions, Please Call our Team on{' '}
          <a href="tel:1300 652738" className="font-bold">
            1300 652738
          </a>{' '}
          or email on{' '}
          <a href="mailto:info@frontlineremovals.com.au" className="font-bold">
            info@frontlineremovals.com.au
          </a>
        </p> */}
      </div>

      <h1 className="mt-24 text-5xl font-semibold leading-none uppercase heading font-display">
        <span className="inline-block">Terms and conditions</span>{' '}
      </h1>

      <div className="mt-6 rich-text">
        <p>
          <strong>
            I/We agree to pay all charges before or at the time of the
            commencement of my/our removal unless prior approval has been
            granted by the Contractor for charges to be debited to a regular and
            approved company account in which case, an official order must
            accompany the Acceptance Form and all charges are then payable on
            presentation of invoice. I/We accept your quotation as set out on
            this form and agree to the Terms and Conditions of your Contract, a
            copy of which is in my possession. In the event of a cancellation of
            your relocation will incur a $110.00 administration fee.
          </strong>
        </p>
        <p>
          Port Removals PTY LTD T/A Frontline Removals &amp; Storage holds a
          number of insurance policies. Our Carriers Liability insurance policy
          covers damage to your items in the event of:
        </p>

        <ul className="pl-6">
          <li>
            Fire,Explosion (subject to our policy regarding transportation of
            dangerous goods eg. gas cylinders, petroleum products. See our
            non-allowable’s list)
          </li>
          <li>Lightning damage</li>
          <li>Flood</li>
          <li>
            Collision or overturning of our truck whilst your belongings are
            being transported by us.
          </li>
        </ul>

        <p>
          Your items are also covered for theft ($500 excess per item) and
          damage incurred during loading and unloading ($1000 excess per item).
          This means that if you choose not to take out any additional
          insurance, in the event of the theft of our truck whilst your
          belongings are inside it, or the theft of some of your goods by a
          third party during your move, you will be liable for the first
          $1000.00 payable per item in the replacement of those items. In making
          a claim for theft it may be necessary to prove ownership of those
          items either with photographs or original purchase receipts (perhaps
          photograph your most important items beforehand). If any items are
          damaged in the loading and unloading of your possessions and you have
          not taken out additional insurance, then you will be liable for the
          first $1000 payable in the replacement or repair of those items. In
          all cases the maximum sum insured is $50,000. This insurance policy
          does not require any additional payment from you or for you to fill
          out any paperwork.
        </p>

        <p>
          If you do wish to take out additional insurance for your move (such as
          if you wish to reduce the excess payable or insure items to a higher
          total value), or even to just discuss your options, please contact our
          insurance broker Elders Insurance on (02) 4041 6600 who will be happy
          to assist you.
        </p>

        <ol className="mt-4">
          <li>
            <p>
              <strong>DEFINITIONS</strong>
              <br />
              In these conditions:
            </p>
            <ol>
              <li>
                <p>
                  “We” means Port Removals Pty Ltd (and our other registered
                  business names, ACN 108466940 and “Us” and “Our” have
                  corresponding meanings;
                </p>
              </li>
              <li>
                <p>
                  “You” means the party entering into the agreement for Services
                  with Us, and includes the party to whom Our quotation is
                  addressed and the party by whom the acceptance is signed, and
                  “Your” has a corresponding meaning;
                </p>
              </li>
              <li>
                <p>
                  “Ancillary Services” means services which are ancillary to the
                  Services, but which We do not offer or provide, including
                  transportation by sea, rail or air, and transportation of
                  vehicles, trailers, caravans, boats and animals;
                </p>
              </li>
              <li>
                <p>
                  “Goods” means all furniture and other effects which are to be
                  the subject of the Services;
                </p>
              </li>
              <li>
                <p>
                  “Services” means the whole of the work to be undertaken by Us
                  in connection with the Goods including removal and (if
                  applicable) storage;
                </p>
              </li>
              <li>
                <p>
                  “Subcontractor” means any person other than one of Our
                  employees who, under any agreement or arrangement with Us
                  (whether directly or indirectly) performs or agrees to perform
                  the whole or any part of the Services;
                </p>
              </li>
              <li>
                <p>
                  “Third Party Provider” means any person who We have arranged
                  to carry out any Ancillary Services;
                </p>
              </li>
              <li>
                <p>
                  Words in the singular include the plural, and words in one or
                  more genders include all genders.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>WE ARE NOT COMMON CARRIERS</strong>
              <br />
              WE ARE NOT COMMON CARRIERS AND ACCEPT NO LIABILITY AS SUCH.
            </p>
            <ol>
              <li>
                <p>
                  We reserve the right to refuse to quote for the carriage of
                  goods for any particular person and for carriage of any goods
                  or classes of goods at Our discretion
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>YOUR OBLIGATIONS AND WARRANTIES </strong>
            </p>
            <ol>
              <li>
                <p>
                  Information supplied by You. You warrant that any information
                  which You have provided to Us and on which We have reasonably
                  relied in assessing any quotation or estimate of the resources
                  necessary to carry out the work is accurate. Anything deemed
                  to be other than reasonable access not communicated to Us by
                  You will result in reasonable additional charges to cover the
                  additional costs associated with completing your move.
                </p>
              </li>
              <li>
                <p>
                  Owner or Authorised Agent. You warrant that, in entering into
                  this agreement, You are either the owner of the Goods, or the
                  authorised agent of the owner.
                </p>
              </li>
              <li>
                <p>
                  Presence at Loading/Unloading. You will ensure that You or
                  some person on your behalf is present when the Goods are
                  loaded or unloaded, except if they are being unloaded into or
                  loaded from our store.
                </p>
              </li>
              <li>
                <p>
                  Dangerous Goods. You warrant that the Goods do not include any
                  firearms or goods which are or may become of a dangerous,
                  corrosive, highly combustible, explosive, damaging or noxious
                  nature nor likely to encourage any vermin or pest unless You
                  have disclosed to Us in writing the presence and nature of any
                  such items prior to them being made available to Us for
                  loading or storage. We may refuse to remove or store such
                  items. If We discover any article or substance of this nature
                  after the Goods have been received by Us, We may take any
                  reasonable action, including destruction or disposal, as We
                  may think fit without incurring any liability to You.
                </p>
              </li>
              <li>
                <p>
                  Fragile Goods and Valuable Items. You will, prior to the
                  commencement of the removal or storage, give to Us written
                  notice of any Goods which are of a fragile or brittle nature
                  and which are not readily apparent as such, or which comprise
                  jewellery, precious objects, works of art, money, collections
                  of items or precision equipment in any case having a value in
                  excess of $1,000.
                </p>
              </li>
              <li>
                <p>
                  Goods Left Behind or Moved in Error. You will ensure, to the
                  best of Your ability, that all Goods to be removed (other than
                  Goods being removed from store) or stored are uplifted by Us
                  and that none is taken in error. Failure to do so will result
                  in additional charges.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>
                METHOD OF CARRIAGE, SUBCONTRACTORS AND ANCILLARY SERVICES
              </strong>
            </p>
            <ol>
              <li>
                <p>
                  Mode of Carriage. We shall be entitled to carry, or arrange
                  for the carriage of, the Goods by any reasonable route (having
                  regard to all the circumstances including the nature and
                  destination of any other goods being carried on or in the
                  conveying vehicle or container) and by any reasonable means,
                  including, where We consider it necessary or desirable, by
                  sea, rail or air, and for that purpose, as Your agent, to
                  arrange for a Third Party Provider effect such carriage by
                  sea, rail or air.
                </p>
              </li>
              <li>
                <p>
                  Subcontractors. We may use a Subcontractor or Subcontractors
                  to undertake the whole or any part of the Services, but if We
                  do so, We will continue to be responsible to You for the
                  performance of the Services.
                </p>
              </li>
              <li>
                <p>
                  Liability of Subcontractors and Employees. Any provisions in
                  these conditions which limit Our liability also apply to Our
                  Subcontractors and to Our employees and to the employees of
                  Our Subcontractors. For the purposes of this subclause, We
                  are, or are deemed to be, acting as agent or trustee on behalf
                  of each of the persons referred to, and each of them shall to
                  that extent be deemed to be parties to this agreement.
                </p>
              </li>
              <li>
                <p>
                  Ancillary Services. We will or may, at Your request and as
                  Your agent, arrange to have Ancillary Services undertaken by
                  Third Party Providers, but We accept no liability, including
                  liability for any loss or damage, arising out of the provision
                  of Ancillary Services. However, if We arrange for a Third
                  Party Provider to undertake carriage of the Goods by sea, rail
                  or air, and the Goods suffer loss or damage at some time when
                  they are either in Our possession or the possession of the
                  Third Party Provider, and if We cannot establish, on a balance
                  of probabilities, that the Goods were in the possession of the
                  Third Party Provider when that loss or damage occurred, the
                  Goods will be deemed to have been in Our possession at the
                  time.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>DELIVERY</strong>
            </p>
            <ol>
              <li>
                <p>
                  We shall not be bound to deliver the Goods except to You or a
                  person authorised in writing by You to receive the Goods. If
                  We cannot deliver the Goods either because there is no
                  authorised person there to receive them on Our arrival, or
                  because We cannot gain access to the premises, or for any
                  other reason beyond Our control, We will be entitled to
                  entitled to charge an additional amount for storage and for
                  the subsequent re-delivery of the Goods. If this happens, We
                  will endeavour to contact you to ascertain whether You have
                  any alternate instructions.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>STORAGE CONDITIONS</p>
            <ol>
              <li>
                <p>
                  Inventory. We will prepare an inventory of Goods received for
                  storage and will ask You to sign that inventory. You will be
                  provided with a copy of the inventory. If You sign the
                  Inventory, or do not do so and fail to object to its accuracy
                  within 7 days of receiving it from Us, the inventory will be
                  conclusive evidence of the Goods received by Us. The inventory
                  will disclose only visible items and not any contents unless
                  You ask for the contents to be listed, in which case We will
                  be entitled to make a reasonable additional charge.
                </p>
              </li>
              <li>
                <p>
                  Contact Address. You agree to advise Us of an address to which
                  We can forward any notice or correspondence, and to promptly
                  notify Us of any change of address.
                </p>
              </li>
              <li>
                <p>
                  Price Changes. Our storage charges will be as quoted to you
                  for the first 26 weeks of storage. After 26 weeks, We may
                  change the storage charges from time to time on giving 28
                  days’ written notice to You.
                </p>
              </li>
              <li>
                <p>
                  Warehouse Change. We are authorised to remove the Goods from
                  one warehouse to another without cost to you. We will notify
                  you of the removal and advise the address of the warehouse to
                  which the Goods are being removed, not less than 5 days before
                  removal (except in emergency, when such notice will be given
                  as soon as possible).
                </p>
              </li>
              <li>
                <p>
                  Inspection of Goods in Store. You are entitled, upon giving us
                  reasonable notice, to inspect the Goods in store, but a
                  reasonable charge may be made by Us for this service.
                </p>
              </li>
              <li>
                <p>
                  Removal from Storage. Subject to payment for the balance of
                  any fixed or minimum period of storage agreed, You may require
                  the Goods to be removed from store at any time on giving Us
                  not less than 10 working days’ notice. If You give Us less
                  notice, We will still use Our best endeavours to meet your
                  requirement, but shall be entitled to make a reasonable
                  additional charge for the short notice.
                </p>
              </li>
              <li>
                <p>
                  Compulsory Removal and Disposal/Sale. You agree to remove the
                  Goods from storage within 28 days of a written notice of
                  requirement from Us to do so. In default, We may, after 14
                  days’ notice to You, SELL ALL OR ANY OF THE GOODS by public
                  auction or on Ebay or a similar online auction sale facility
                  or and apply the net proceeds in satisfaction of any amount
                  owing by You to Us.
                </p>
              </li>
              <li>
                <p>
                  Sale of Goods. For the purposes of preparing for the sale of
                  the Goods under clauses 6.7 or 7.5 or otherwise as permitted
                  by law, We are authorised by You to open any boxes in storage
                  to inspect and identify the contents and We may at Our
                  discretion decide which contents will be offered for sale to
                  the general public. At Our discretion, any items we do not
                  offer for sale may be stored by Us for such period as We
                  consider appropriate and We may invite You to collect those
                  items from Us once We have been paid all moneys due by You to
                  Us for Services provided under this or any other agreement. If
                  We fail to sell the Goods at public auction or Ebay, We may at
                  Our option pay $1 for the Goods and at Our discretion dispose
                  of the Goods.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>CHARGES AND PAYMENTS</strong>
            </p>
            <ol>
              <li>
                <p>
                  Variation of Work Required and Delay. If the work You
                  ultimately require Us to do varies from the work for which a
                  quotation or estimate has been given, or if We are prevented
                  from or delayed in undertaking the Services or any part
                  thereof (except where that prevention or delay results from a
                  factor within Our control), we will also be entitled to make a
                  reasonable additional charge. We will also be entitled to
                  reimbursement from You of any amount which We have been
                  required to pay to a third party (other than a Subcontractor)
                  to obtain or effect delivery of the Goods.
                </p>
              </li>
              <li>
                <p>
                  Alteration of Dates. If a date for the performance by Us of
                  any Services is agreed upon in the quotation and acceptance or
                  subsequently, and You require that date to be altered or the
                  Goods are not available on that date, We will be entitled to
                  make a reasonable additional charge for any loss or additional
                  expense occasioned by such alteration or unavailability.
                </p>
              </li>
              <li>
                <p>
                  Payment by Third Party. If You arrange with Us or instruct Us
                  that Our charges are to be paid by a third party, and if that
                  party does not pay the charges within 14 days of the date set
                  for payment or, if no date is set for payment, within 14 days
                  of the date of invoice, You agree to thereupon pay the
                  charges.
                </p>
              </li>
              <li>
                <p>
                  Default Charges. If amounts are outstanding from You to Us for
                  more than 30 days, We will be entitled to charge interest at
                  the Commonwealth Bank maximum personal overdraft interest rate
                  for amounts not exceeding $100,000 from time to time,
                  calculated on monthly rests.
                </p>
              </li>
              <li>
                <p>
                  Contractual Liens. All Goods received by Us will be subject to
                  a general lien for any moneys due by You to Us relating to any
                  Services provided under this or any other agreement. Without
                  prejudice to any other rights which We may have under this
                  contract or otherwise at law, if any amounts have been
                  outstanding for a period of 26 weeks, We may give 28 days’
                  written notice to You of intention to sell, and if the
                  outstanding amount is not paid within that period, We may SELL
                  ALL OR ANY OF THE GOODS and exercise any other rights We have
                  under clause 6.8 relating to the sale of the Goodsand apply
                  the net proceeds in satisfaction of the amount due.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>LOSS OR DAMAGE – PRIVATE REMOVALS AND STORAGE</strong>
            </p>
            <ol>
              <li>
                <p>
                  Australian Consumer Law. Except where the Services are
                  required by You for the purposes of a business, trade,
                  profession or occupation in which You are engaged, this
                  agreement will be subject to the guarantees set out in
                  sections 60, 61 and 62 of the Australian Consumer Law (as
                  enacted as Schedule 2 of the Competition and Consumer Act
                  2010) being, in particular, a guarantee that the Services will
                  be rendered with due care and skill, and the following
                  conditions of this clause 8 will apply.
                </p>
              </li>
              <li>
                <p>
                  Negligence. We will only be liable for the proportion to which
                  the loss or damage to the Goods is caused by or contributed to
                  by Our negligence (including the negligence of any
                  Subcontractor). We will not be liable for loss or damage to
                  the Goods caused or contributed to by You or someone else that
                  We are not responsible for at law
                </p>
              </li>
              <li>
                <p>
                  Exclusions. We will not be liable for any loss or damage nor
                  any delay which results from any cause beyond Our control,
                  including any loss or damage occurring in the course of the
                  provision of Ancillary Services by Third Party Providers.
                </p>
              </li>
              <li>
                <p>
                  Damage to Goods – Packaging. If the Goods sustain damage by
                  reason of defective or inadequate packing or unpacking, and
                  the packing or unpacking (as the case may be) was not
                  undertaken by Us or a Subcontractor, We will not be liable.
                </p>
              </li>
              <li>
                <p>
                  Damage to Goods – Inherent Risk. Certain goods (including
                  electrical and mechanical appliances, computer equipment,
                  scientific instruments and certain musical instruments) are
                  inherently susceptible to suffer damage or disorder upon
                  removal. Unless that damage or disorder results from a failure
                  to exercise due care and skill on Our part, We will not be
                  liable.
                </p>
              </li>
              <li>
                <p>
                  Damage to Goods – Furniture Items. If You have elected not to
                  take out any insurance over furniture items whilst in transit
                  or storage and We (or Our Subcontractor) did not package any
                  furniture items for You prior to transit or storage, We will
                  only be liable for damage to the furniture items up to an
                  amount of $1000.00 per item. This cap on Our liability to You
                  does not apply if there is evidence that We (or Our
                  Subcontractor) failed to exercise due care and skill and this
                  failure is what caused the damage.
                </p>
              </li>
              <li>
                <p>
                  Lost, Stolen or Misplaced Items. If You have elected not to
                  take out any insurance over the Goods whilst in transit or
                  storage, and We (or Our Subcontractor) have not packed the
                  Goods for You, We will only be liable for lost, stolen or
                  misplaced boxes or items up to an amount of $300 per box. This
                  cap on Our liability to You does not apply if You provided Us
                  with a completed inventory including valuation of the contents
                  of the box containing the Goods prior to commencement of
                  transit or storage and there is evidence that We (or Our
                  Subcontractor) failed to reasonably secure the Goods whilst in
                  Our custody or care.
                </p>
              </li>
              <li>
                <p>
                  Notification of Loss or Damage. You will be asked to sign an
                  inventory or other documents at the conclusion of the transit
                  and You are responsible at that time to confirm that all of
                  the Goods have been delivered, there are no missing items or
                  boxes and the Goods are in acceptable condition. Any claim for
                  loss or damage under this clause 8 is to be notified by You to
                  Us in writing, or by telephone and later confirmed in writing,
                  as soon as possible and within a reasonable time after the
                  date of delivery. We will have the best chance of locating any
                  misplaced items, or ascertaining the cause of damage, if that
                  notification is given to Us within 2 working days.
                </p>
              </li>
              <li>
                <p>
                  Maximum Value of Goods. In any claim for loss or damage under
                  this clause 8, any estimate of the inventory and value of the
                  Goods which You have provided to Us, whether for the purposes
                  of insurance or otherwise, will be prima facie evidence that
                  the total value of the Goods did not exceed that estimate at
                  the time of loss or damage.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>LOSS OR DAMAGE – COMMERCIAL REMOVALS AND STORAGE</strong>
            </p>
            <ol>
              <li>
                <p>
                  Application. If the Services are required by You for the
                  purposes of a business, trade, profession or occupation in
                  which You are engaged, the following conditions of this clause
                  9 will apply.
                </p>
              </li>
              <li>
                <p>
                  Negligence. We will only be liable for the proportion to which
                  the loss or damage to the Goods is caused by or contributed to
                  by Our negligence (including the negligence of any
                  Subcontractor, but excluding the negligence of any Third Party
                  Provider), and in any event that liability will be limited to
                  $100 per item or package, or $1,000 in respect of all Goods
                  moved or stored under this agreement (whichever is the
                  lesser).
                </p>
              </li>
              <li>
                <p>
                  Claims. You will be asked to sign an inventory or other
                  document at the conclusion of the transit and You are
                  responsible at that time to confirm that all of the Goods have
                  been delivered, there are no missing items or boxes and the
                  Goods are in acceptable condition. Once you have signed this
                  we assume you have received all your goods in total and in the
                  appropriate acceptable condition. We will have no further
                  liability.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>INSURANCE</strong>
            </p>
            <ol>
              <li>
                <p>
                  You can arrange insurance with an insurer of your choice and
                  we encourage you to do so
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>DISPUTES</strong>
            </p>
            <ol>
              <li>
                <p>
                  Notification of Dispute. If You or We consider that a dispute
                  has arisen in relation to this agreement (either during the
                  Services, or after they have been completed), written notice
                  of the dispute will be given to the other party. Even if that
                  notice is given, You and We must continue to perform any
                  obligations outstanding by Us under the agreement.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>VARIATION AND NOTICE</strong>
            </p>
            <ol>
              <li>
                <p>
                  Variation. The terms of these conditions cannot be varied
                  other than by Your and Our mutual consent. Our consent can
                  only be given by a proprietor, director, secretary or manager,
                  and must be evidenced in writing.
                </p>
              </li>
              <li>
                <p>
                  Notice. Any notice to be given by Us to You may be given
                  personally or by prepaid post addressed to Your address last
                  known to Us, or by facsimile to a facsimile number at that
                  address, or by electronic pmail.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>APPLICABLE LAW</strong>
            </p>
            <ol>
              <li>
                <p>
                  The law which governs this agreement will be the law
                  applicable in the place in which the agreement is made.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>

      <h2 className="mt-24 text-5xl font-semibold leading-none uppercase heading font-display">
        <span className="inline-block">CONDITIONS OF STORAGE</span>{' '}
      </h2>

      <div className="mt-6 rich-text">
        <ol>
          <li>
            <p>
              Port Removals Pty Ltd will allow the Occupier whose name is noted
              on the face of this document a space for a period of time or until
              expiration, cancellation or notice of termination is given by Port
              Removals Pty Ltd.
            </p>
          </li>
          <li>
            <p>
              The Occupier shall not, without the consent of Port Removals Pty
              Ltd, or its agent, store any goods that are or contain substances
              of a dangerous, perishable, spoiling, explosive, inflammable or
              corrosive nature in the space.
            </p>
          </li>
          <li>
            <p>
              The Occupier will be responsible at all times for insurance of all
              goods stored in the space allotted and will be responsible for the
              security of the goods. Port Removals Pty Ltd or its agents shall
              not be liable for burglary, theft, or damage, deterioration in any
              goods including, without limitation, damage caused by floods,
              leakage or overflow of water, heat or fire, overflow or spillage
              of goods from other spaces within the building, pest or vermin.
            </p>
          </li>
          <li>
            <p>
              Port Removals Pty Ltd or its agents will not be responsible for
              any damage caused to the goods and chattels while in storage.
            </p>
          </li>
          <li>
            <p>
              Port Removals Pty Ltd or its agents will not be responsible for
              any damage caused by an act of God or accident, however caused,
              damage caused by flood, fire, storm or tempest or nuclear
              explosion.
            </p>
          </li>
          <li>
            <p>
              The Occupier indemnifies and keeps indemnified Port Removals Pty
              Ltd and its agents from and against all claims, actions, demands,
              costs, damages and proceedings for or in respect of the death or
              injury of any person or the damage to any property of the Occupier
              or any other person in relation to the goods in storage.
            </p>
          </li>
          <li>
            <p>
              Port Removals Pty Ltd or its agent shall be entitled, that in the
              event Port Removals Pty Ltd has to remove goods or chattels left
              by the occupier or put the space in a clean and undamaged
              condition, to make a deduction or claim for such expenses of
              damage, removal and cleaning.
            </p>
          </li>
          <li>
            <p>
              In the event that the Occupier shall be in arrears of payment of
              the rent or fees for any period, Port Removals Pty Ltd or its
              agent shall have the right to charge interest on unpaid rent or
              fee or forthwith cancel the storage facility and remove the goods
              from the space and Port Removals Pty Ltd or its agent will not be
              responsible for any loss, damage or deterioration of the goods
              whatsoever.
            </p>
          </li>
          <li>
            <p>
              Any damage to the space or any other property of Port removals Pty
              Ltd or its agents, shall be paid for or reimbursed to Port
              Removals Pty Ltd by the Occupier forthwith upon Port Removals Pty
              Ltd giving notice in writing to the Occupier of the amount.
            </p>
          </li>
          <li>
            <p>
              The Occupier claims total legal and lawful ownership of goods in
              the storage space, and the condition of storage is under the
              Licence Act.
            </p>
          </li>
          <li>
            <p>
              Deliveries and removals from the Occupier’s space will not be
              permitted by any person other than Port Removals Pty Ltd or its
              agents.
            </p>
          </li>
          <li>
            <p>The Occupier is responsible for any Government charges.</p>
          </li>
          <li>
            <p>
              Fourteen days notice prior to vacating is required by Port
              Removals Pty Ltd or its agents.
            </p>
          </li>
          <li>
            <p>
              Rent of fees are to be paid in advance in accordance with the
              agreement.
            </p>
          </li>
          <li>
            <p>
              If rent becomes three months in arrears goods in the designated
              space will be sold to recover expenses.
            </p>
          </li>
          <li>
            <p>
              The Occupier agrees to be bound by these and any further terms and
              conditions as may be imposed from time to time in writing by Port
              Removals Pty Ltd or its agents.
            </p>
          </li>
        </ol>
      </div>
    </article>
  </Layout>
);

export default TermsAndConditions;
